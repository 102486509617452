@import url('https://fonts.googleapis.com/css2?family=Anton&family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Epilogue:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=IBM+Plex+Sans:wght@100;200;300;500&family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Luckiest+Guy&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500&family=Noto+Sans:ital,wght@0,400;0,700;1,400;1,700&family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Raleway:ital,wght@0,100;0,300;0,400;0,600;0,700;0,900;1,600;1,800&family=Readex+Pro:wght@200;300;400;500;600;700&family=Recursive:wght@300;400;500;600;700;800;900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Share+Tech&family=Ubuntu:ital,wght@0,300;0,500;1,400;1,500;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anton&family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Epilogue:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=IBM+Plex+Sans:wght@100;200;300;500&family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Luckiest+Guy&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500&family=Noto+Sans:ital,wght@0,400;0,700;1,400;1,700&family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Raleway:ital,wght@0,100;0,300;0,400;0,600;0,700;0,900;1,600;1,800&family=Readex+Pro:wght@200;300;400;500;600;700&family=Recursive:wght@300;400;500;600;700;800;900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Share+Tech&family=Ubuntu:ital,wght@0,300;0,500;1,400;1,500;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anton&family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Epilogue:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=IBM+Plex+Sans:wght@100;200;300;500&family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Luckiest+Guy&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500&family=Noto+Sans:ital,wght@0,400;0,700;1,400;1,700&family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Raleway:ital,wght@0,100;0,300;0,400;0,600;0,700;0,900;1,600;1,800&family=Readex+Pro:wght@200;300;400;500;600;700&family=Recursive:wght@300;400;500;600;700;800;900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Share+Tech&family=Ubuntu:ital,wght@0,300;0,500;1,400;1,500;1,700&display=swap');

* {
    margin: 0;
    padding: 0;
    outline: 0;
}

:root {
    /* Existing font-family variables */
    --font-openSans: 'Open Sans', sans-serif;
    --font-poppins: 'Poppins', sans-serif;
    --font-roboto: 'Roboto', sans-serif;

    /* NEW color palette (with your custom names) */
    --color-mustard: #c3ba3f;    /* Mustard */
    --color-green: #366631;      /* Green */
    --color-aqua: #5fc0c6;       /* Aqua */
    --color-olive: #9ba454;      /* Olive */
    --color-sky-blue: #acddd6;   /* Sky Blue */
    --color-tan-beige: #dfdbc5;  /* Tannish Beige */
    --color-gold: #D2AF40;
    --color-light-gold: #E9DEA6;
    --color-grey: #656453;
}

a {
    text-decoration: none !important;
}

.text_gold {
    color: var(--color-gold) !important;
}

.text_grey {
    color: var(--color-grey) !important;
}

.op_1 {
    opacity: 1 !important;
}

.py_100 {
    padding-top: 100px;
    padding-bottom: 100px;
}

.pt_100 {
    padding-top: 100px;
}

.pb_100 {
    padding-bottom: 100px;
}

/* delete_registry_card_button button */
.delete_registry_card_button {
    font-family: var(--font-roboto);
    border: 1px solid #65645359;
    border-radius: 20px;
    padding: 10px 41px;
    color: var(--color-grey);
    background: transparent;
    text-transform: uppercase;
}

.delete_registry_card_button:hover {
    background: var(--color-grey);
    color: #fff;
}

/* common button */
.button1 {
    font-family: var(--font-roboto);
    border: 1px solid #65645359;
    border-radius: 20px;
    padding: 10px 20px;
    color: var(--color-grey);
    background: transparent;
    text-transform: uppercase;
}

.button1:hover {
    background: var(--color-grey);
    color: #fff;
}

.button2 {
    font-family: var(--font-roboto);
    border-radius: 20px;
    padding: 10px 20px;
    color: #fff;
    background: var(--color-gold);
    border: 0;
    text-transform: uppercase;
}

.button2:hover {
    background: var(--color-grey);
    color: #fff;
}

.button3 {
    font-family: var(--font-roboto);
    border: 1px solid #fff;
    border-radius: 20px;
    padding: 10px 20px;
    color: #fff;
    background: transparent;
    text-transform: uppercase;
}


.button3:hover {
    background: var(--color-grey);
    color: #fff;
    border: 1px solid #656453;
}

/* common button */

/* common pargraph */
.para1 {
    font-size: 17px;
    font-family: var(--font-roboto);
    font-weight: 300;
    color: #5D5C61;
}

.dropdown a:hover {
    background-color: #fff !important;
    color: #040404 !important;
}

.para2 {
    font-size: 15px;
    font-family: var(--font-roboto);
    font-weight: 300;
    color: #5D5C61;
    opacity: .8;
}

/* common pargraph */


/* common input start */

.input_search {
    border: 1px solid #C1C4D1;
    border-radius: 20px;
    background-color: transparent;
    font-family: var(--font-roboto);
    padding: 10px 20px;
    font-size: 18px;
    color: #252849;
}

.serachIcon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
}

.input_search::placeholder {
    color: #252849;
    font-size: 15px;
}

.input_field {
    border: 1px solid #C1C4D1;
    border-radius: 20px;
    background-color: #fff;
    color: var(--color-grey);
    font-family: var(--font-roboto);
    padding: 10px 20px;
}

.input_label {
    display: block;
    margin-bottom: 10px;
    color: var(--color-grey);
    font-family: var(--font-roboto);
    margin-left: 10px;
    font-size: 18px;
}

.AiFillEye {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 20px;
    cursor: pointer;
    color: #3F3D56;
}

.select {
    color: #252849;
    cursor: pointer;
}

/* common input end */
