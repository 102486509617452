.fsp {
  position: fixed;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;
  width: 100%;
  height: 100vh;
  z-index: 220 !important;
  align-items: center;
  justify-content: center;
}

.fsp_overlay {
  position: fixed;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;
  margin: auto;
  width: 100% !important;
  height: 100vh !important;
  z-index: 222 !important;
  background: #00000050;
}

.fsp_content_wrapper {
  position: absolute;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;
  background: #ffffb3;
  border: 1px solid #65645359;
  border-radius: 20px;
  margin: auto;
  overflow-x: auto;
  z-index: 223 !important;
  width: 400px;
  height: fit-content;
  transition: all 0.3s;
}

.fsp_header {
  border-bottom: 1.2px solid #eaedf3;
  height: 70px;
  padding: 0px 24px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.fsp_content {
  padding: 24px;
  width: 100%;
}

@media (max-width: 640px) {
  .fsp_content_wrapper {
    width: 95% !important
  }
}
