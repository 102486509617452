@import url(https://fonts.googleapis.com/css2?family=Anton&family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Epilogue:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=IBM+Plex+Sans:wght@100;200;300;500&family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Luckiest+Guy&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500&family=Noto+Sans:ital,wght@0,400;0,700;1,400;1,700&family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Raleway:ital,wght@0,100;0,300;0,400;0,600;0,700;0,900;1,600;1,800&family=Readex+Pro:wght@200;300;400;500;600;700&family=Recursive:wght@300;400;500;600;700;800;900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Share+Tech&family=Ubuntu:ital,wght@0,300;0,500;1,400;1,500;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Anton&family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Epilogue:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=IBM+Plex+Sans:wght@100;200;300;500&family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Luckiest+Guy&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500&family=Noto+Sans:ital,wght@0,400;0,700;1,400;1,700&family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Raleway:ital,wght@0,100;0,300;0,400;0,600;0,700;0,900;1,600;1,800&family=Readex+Pro:wght@200;300;400;500;600;700&family=Recursive:wght@300;400;500;600;700;800;900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Share+Tech&family=Ubuntu:ital,wght@0,300;0,500;1,400;1,500;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Anton&family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Epilogue:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=IBM+Plex+Sans:wght@100;200;300;500&family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Luckiest+Guy&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500&family=Noto+Sans:ital,wght@0,400;0,700;1,400;1,700&family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Raleway:ital,wght@0,100;0,300;0,400;0,600;0,700;0,900;1,600;1,800&family=Readex+Pro:wght@200;300;400;500;600;700&family=Recursive:wght@300;400;500;600;700;800;900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Share+Tech&family=Ubuntu:ital,wght@0,300;0,500;1,400;1,500;1,700&display=swap);
* {
    margin: 0;
    padding: 0;
    outline: 0;
}

:root {
    /* Existing font-family variables */
    --font-openSans: 'Open Sans', sans-serif;
    --font-poppins: 'Poppins', sans-serif;
    --font-roboto: 'Roboto', sans-serif;

    /* NEW color palette (with your custom names) */
    --color-mustard: #c3ba3f;    /* Mustard */
    --color-green: #366631;      /* Green */
    --color-aqua: #5fc0c6;       /* Aqua */
    --color-olive: #9ba454;      /* Olive */
    --color-sky-blue: #acddd6;   /* Sky Blue */
    --color-tan-beige: #dfdbc5;  /* Tannish Beige */
    --color-gold: #D2AF40;
    --color-light-gold: #E9DEA6;
    --color-grey: #656453;
}

a {
    text-decoration: none !important;
}

.text_gold {
    color: var(--color-gold) !important;
}

.text_grey {
    color: var(--color-grey) !important;
}

.op_1 {
    opacity: 1 !important;
}

.py_100 {
    padding-top: 100px;
    padding-bottom: 100px;
}

.pt_100 {
    padding-top: 100px;
}

.pb_100 {
    padding-bottom: 100px;
}

/* delete_registry_card_button button */
.delete_registry_card_button {
    font-family: var(--font-roboto);
    border: 1px solid #65645359;
    border-radius: 20px;
    padding: 10px 41px;
    color: var(--color-grey);
    background: transparent;
    text-transform: uppercase;
}

.delete_registry_card_button:hover {
    background: var(--color-grey);
    color: #fff;
}

/* common button */
.button1 {
    font-family: var(--font-roboto);
    border: 1px solid #65645359;
    border-radius: 20px;
    padding: 10px 20px;
    color: var(--color-grey);
    background: transparent;
    text-transform: uppercase;
}

.button1:hover {
    background: var(--color-grey);
    color: #fff;
}

.button2 {
    font-family: var(--font-roboto);
    border-radius: 20px;
    padding: 10px 20px;
    color: #fff;
    background: var(--color-gold);
    border: 0;
    text-transform: uppercase;
}

.button2:hover {
    background: var(--color-grey);
    color: #fff;
}

.button3 {
    font-family: var(--font-roboto);
    border: 1px solid #fff;
    border-radius: 20px;
    padding: 10px 20px;
    color: #fff;
    background: transparent;
    text-transform: uppercase;
}


.button3:hover {
    background: var(--color-grey);
    color: #fff;
    border: 1px solid #656453;
}

/* common button */

/* common pargraph */
.para1 {
    font-size: 17px;
    font-family: var(--font-roboto);
    font-weight: 300;
    color: #5D5C61;
}

.dropdown a:hover {
    background-color: #fff !important;
    color: #040404 !important;
}

.para2 {
    font-size: 15px;
    font-family: var(--font-roboto);
    font-weight: 300;
    color: #5D5C61;
    opacity: .8;
}

/* common pargraph */


/* common input start */

.input_search {
    border: 1px solid #C1C4D1;
    border-radius: 20px;
    background-color: transparent;
    font-family: var(--font-roboto);
    padding: 10px 20px;
    font-size: 18px;
    color: #252849;
}

.serachIcon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
}

.input_search::placeholder {
    color: #252849;
    font-size: 15px;
}

.input_field {
    border: 1px solid #C1C4D1;
    border-radius: 20px;
    background-color: #fff;
    color: var(--color-grey);
    font-family: var(--font-roboto);
    padding: 10px 20px;
}

.input_label {
    display: block;
    margin-bottom: 10px;
    color: var(--color-grey);
    font-family: var(--font-roboto);
    margin-left: 10px;
    font-size: 18px;
}

.AiFillEye {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 20px;
    cursor: pointer;
    color: #3F3D56;
}

.select {
    color: #252849;
    cursor: pointer;
}

/* common input end */

/* Header style start */
.header {
    height: 100px;
    display: grid;
    align-items: center;
    background-color: transparent;
}

.logo_container {
    width: 134px;
    display: flex;
    align-items: center;
}

.logo_text {
    font-family: var(--font-openSans);
    font-size: 30px;
    font-weight: bold;
    margin-left: 12px;
    margin-bottom: 0px;
    color: var(--color-grey);
    text-transform: uppercase;
}

.nav_main {
    display: grid;
    grid-template-columns: 137px 1fr 262px;
    width: 85%;
    margin: 0 auto;
}

.nav_main_child2 {
    display: flex;
    align-items: center;
    justify-content: center;
}

.nav_main_child2 li {
    display: inline;
}

.nav_main_child2 a:hover {
    background-color: #DBDBD9;
    border-radius: 20px;
}

.nav_main_child2 li a {
    color: var(--color-grey);
    font-family: var(--font-roboto);
    font-size: 18px;
    padding: 15px;
}

.nav_main_child3 {
    display: flex;
    justify-content: flex-end;
}

.logo_size {
    width: 36px;
}

.shopping_cart_img {
    font-size: 40px;
    display: inline;
    border: 1px solid var(--color-grey);
    padding: 5px;
    border-radius: 50%;
    color: var(--color-grey);
}

.signuptbtnli {
    display: none !important;
}

.loginbtnli {
    display: none !important;
    margin-top: 10px;
}

.humberger_menu {
    display: none;
}

/* Header style end */

/* footer style start */
.footer {
    font-family: var(--font-roboto);
    color: var(--color-grey);
    padding-top: 50px;
}

.footer p {
    opacity: 0.5;
}

.footer_grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
}

.f_title {
    font-size: 22px;
    font-weight: bold;
}

.fg_child li:first-child {
    margin-top: 0px;
}

.fg_child li {
    margin-top: 25px;
}

.fg_child a {
    color: var(--color-grey);
    opacity: 0.5;
}

.fg_child a:hover {
    color: var(--color-grey);
    opacity: 1;
}

.f_social_main {
    display: flex;
    grid-gap: 15px;
    gap: 15px;
}

.f_social_main a {
    color: #fff;
}

.f_social_main a:hover {
    color: #fff;
}

.f_social_main > div {
    background-color: var(--color-gold);
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    border-radius: 50%;
}

/* footer style end */


/* home Start */
/* ---------------- */
/* section1 */
.h_section1 {
    min-height: auto;
    background-color: var(--color-sky-blue);
    position: relative;
    background-image: url(/static/media/banner-ovreflow.c5cb9da7.png);
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
}

.slider_grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    min-height: 650px;
}

.swiper-pagination {
    bottom: initial !important;
    left: initial !important;
    width: auto !important;
    right: 32px;
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px;
    top: 50%;
    transform: translateY(-50%);
}

.swiper-pagination-bullet-active {
    background-color: #656453 !important;
    border: 2px solid #E0E2E1 !important;
    outline: 1px solid #656453 !important;
}

.swiper-pagination-bullet {
    width: 15px !important;
    height: 15px !important;
}

.sliderimg {
    width: 100%;
}

.sg_child1 {
    display: flex;
    justify-content: center;
    align-items: center;
}

.slider_grid div:nth-of-type(2) {
    display: flex;
    justify-content: center;
    align-items: center;
}

.titlep1 {
    font-size: 40px;
    font-family: var(--font-poppins);
    font-weight: 300;
    color: var(--color-grey);
}

.titlep2 {
    font-size: 40px;
    font-family: var(--font-poppins);
    font-weight: 800;
    color: var(--color-grey);
}

.h_social_main {
    display: flex;
    flex-direction: column;
    grid-gap: 16px;
    gap: 16px;
    position: absolute;
    left: 20px;
    top: 49%;
    z-index: 2;
    font-size: 22px;
}

.h_social_main a {
    color: var(--color-grey);
    opacity: .3;
    /* transform: translateX(-50%); */
}

.h_social_main a:hover {
    color: var(--color-grey);
    opacity: 1;
}

.chatIconDiv {
    background-color: #656453;
    border-radius: 50%;
    display: inline;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 49px;
    bottom: 75px;
    cursor: pointer;
    z-index: 2;
}

.chatIcon {
    width: 25px;
}

.h_s1_carrosal_main {
    background-color: #fff;
    box-shadow: 0px 0px 40px rgb(0 0 0 / 37%);
    border-radius: 20px;
    padding: 30px 50px;
    position: relative;
    margin-top: -80px;
    z-index: 2;
}

/* alice carousel start */
.alice-carousel__dots {
    display: none;
}

.alice_div {
    display: flex;
    justify-content: center;
}

.alice_div img {
    text-align: center;
    display: block;
    margin: 0 auto;
}

.alice_div p {
    margin-top: 15px;
    margin-bottom: 0;
}

.slider_arrow {
    width: 40px;
    height: 40px;
    background-color: #656453;
    opacity: .2;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
}

.slider_arrow:hover {
    background-color: #656453;
    opacity: 1;
}

.arrow_Icon {
    font-size: 20px;
    color: #fff;
}

.left_arrow {
    position: absolute;
    left: -30px;
    top: 50%;
    transform: translateY(-50%);
}

.right_arrow {
    position: absolute;
    right: -30px;
    top: 50%;
    transform: translateY(-50%);
}

/* alice carousel end */

/* section1 */


/* section2 */
.h_section2_grid {
    display: grid;
    margin: 50px 0px;
}

.s2img {
    width: 100%;
}

.ts_alice_div {
    background-color: #F6F6F6;
    padding: 20px;
    border-radius: 20px;
    margin: 0px 20px;
}

.stars {
    width: 130px;
}

.ts_alice_div p {
    font-family: var(--font-openSans);
    font-size: 22px;
    color: #040404;
}

/* section2 */

/* swction3 */
.h_section3 {
    background-image: url(/static/media/h_s3_overflow.176c3d81.png);
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
}

/* swction3 */

/* section4 */
.service_main {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
}

.h_service_card {
    position: relative;
    overflow: hidden;
    border-radius: 20px;
}

.service_img {
    width: 100%;
    border-radius: 20px;
    height: 350px;
    object-fit: cover;
    transform: scale(1);
    transition: transform 0.3s;
}

.overlay:hover .service_img {
    transform: scale(1.3);
}

.service_img_cap {
    position: absolute;
    bottom: 20px;
    left: 20px;
    margin-bottom: 0;
    color: #fff;
    font-weight: 900;
    font-family: var(--font-poppins);

}

.overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0.5;
    background: linear-gradient(179deg, transparent 57%, rgba(0, 0, 0, 0.896796218487395) 85%);
    border-radius: 20px;
}


/* section4 */

/* section5 */
/* section5 */

/* section6 home footer*/
.h_footer {
    background-image: url(/static/media/h_s3_overflow.176c3d81.png);
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
}

.footer_banner_main {
    background-color: var(--color-light-gold);
    padding: 70px 30px;
    border-radius: 20px;
    position: relative;
}

.footer_bnner_s_btn {
    margin-left: 10px;
}

.fbm_card {
    width: 107px;
    align-items: center;
    height: 100px;
    background: #fff;
    display: flex;
    justify-content: center;
    border: 1px solid #D8DFF2;
    box-shadow: 0px 0px 20px rgb(128 128 128 / 33%);
    border-radius: 10px;
    position: absolute;
    text-align: center;
}

.fbm_card_left {
    left: -27px;
    transform: rotate(20deg);
    bottom: 30px;
}

.fbm_card_right {
    right: -27px;
    top: 30px;
    transform: rotate(-20deg);
}

.fbm_card img {
    width: 35px;
}

/* section6 home footer*/
/* --------------------- */
/* home End */


/* Registration Area Start */
.Registration {
    background-image: url(/static/media/h_s3_overflow.176c3d81.png);
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    min-height: 100vh;
}

.weh_main {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    margin-top: 50px;
    grid-gap: 20px;
}

.weh_child {
    background-color: #F6F2DB;
    height: 230px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    cursor: pointer;
    position: relative;
}

.shild {
    height: 230px;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: red;
}

.weh_child:hover {
    background-color: transparent;
}

.weh_child:hover .wc_c {
    display: none;
}

.react_img {
    height: 100%;
    width: 100%;
    object-fit: contain;
    display: none;
}

.weh_child:hover .react_img {
    display: block;
}

.weh_child p {
    text-align: center;
}


/* Reaction to answer  */
.rta_main {
    display: flex;
    height: 84vh;
    align-items: center;
    justify-content: center;
    text-align: center;
}

/* Reaction to answer  */

/* How they are feelingHow they are feeling */
.fh_main {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 30px;
}

.ex_icon {
    width: 100%;
    cursor: pointer;
}

/* How they are feelingHow they are feeling */

/* sign up start */
.signup_container {
    max-width: 670px;
    margin: 0 auto;
}

.AiFillLock {
    font-size: 22px;
    margin-right: 5px;
    position: relative;
    top: -2px;
}

/* regi checkbox */
.r_container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    user-select: none;
}

.r_container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.r_container .checkmark {
    position: absolute;
    top: 4px;
    left: 0;
    height: 25px;
    width: 25px;
    border: 1px solid #C1C4D1;
    border-radius: 5px;
}

.r_container input:checked ~ .checkmark {
    background-color: transparent;
}

.r_container .checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.r_container input:checked ~ .checkmark:after {
    display: block;
}

.r_container .checkmark:after {
    left: 3px;
    top: 3px;
    width: 17px;
    height: 17px;
    background: #C1C4D1;
    border-radius: 5px;
}

.r_container {
    color: #656453;
}

/* regi checkbox */

/* sign up end */

/* Registration Area End */


/* profile area start */
.userinfo_div {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.userimg {
    width: 70px;
    height: 70px;
    object-fit: cover;
    border-radius: 50%;
    border: 2px solid #d2b04093;
    padding: 2px;
}

.profile_container {
    width: 90%;
    margin: 0 auto;
    min-height: 650px;
}

.p_layout {
    display: grid;
    grid-template-columns: 270px 1fr;
    grid-gap: 30px;
}

.img_overlay {
    background-image: url(/static/media/h_s3_overflow.176c3d81.png);
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
}

.p_card1 {
    background-color: #F6F2DB;
    text-align: center;
    border-radius: 25px;
    font-family: var(--font-roboto);
    padding: 50px 0px;
    position: relative;
}

.spcard {
    text-align: start;
}

.p_profileImg {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 50%;
    border: 2px solid #d2b04093;
    padding: 3px;
}

.plusIcon {
    position: absolute;
    bottom: 28px;
    cursor: pointer;
    left: 100px;
}

.plusIconOther {
    position: absolute;
    bottom: 28px;
    cursor: pointer;
    right: 0px;
}
.username {
    margin-top: 15px;
    font-size: 22px;
    font-weight: 400;
}

.registry_name {
    font-weight: 400;
}


.service_registry_card {
    background-color: #D2AF40;
    text-align: center;
    border-radius: 25px;
    padding: 20px 0px;
    position: relative;
}

.delete_registry_card {
    text-align: center;
    border-radius: 25px
}

.p_card2 {
    background-color: #D2AF40;
    text-align: center;
    border-radius: 25px;
    padding: 20px 0px;
    position: relative;
}

.select_area {
    display: flex;
    justify-content: space-between;
    grid-gap: 30px;
    gap: 30px;
    cursor: pointer;
}

.p_card {
    border: 1px solid #E4E7EA;
    padding: 20px;
    background-color: #fff;
    display: grid;
    font-family: var(--font-roboto);
    border-radius: 10px;
    grid-gap: 15px;
}

.grid_card_registry {
    grid-template-columns: 160px 2fr 120px 0px;
}

.grid_card_services {
    grid-template-columns: 160px 2fr 120px 150px;
}

.p_c_img {
    width: 142px;
    height: 142px;
    object-fit: cover;
    border-radius: 50%;
    border: 3px solid #25284923;
    padding: 3px;
}

.pc_child {
    display: flex;
    align-items: center;
    justify-content: center;
}

.p_card_child2 {
    display: grid !important;
    justify-content: initial !important;
}

.pc_title {
    color: #D2AF40;
    font-weight: 600;
}

.star_text {
    color: #D2AF40;
    font-size: 20px;
}

.star_text::before {
    content: url(/static/media/star.db4290f9.svg);
    position: relative;
    top: 2px;
    margin-right: 5px;
}

.p_para1 {
    font-size: 15px;
    color: #656453;
    margin: 0;
}

.p_op1::before {
    content: url(/static/media/video.bd4cd0ae.svg);
    position: relative;
    top: 2px;
    margin-right: 5px;
}

.p_op2::before {
    content: url(/static/media/location.532e10c2.svg);
    position: relative;
    top: 4px;
    margin-right: 5px;
}

.p_op3::before {
    content: url(/static/media/active.4f7d92cd.svg);
    position: relative;
    top: 4px;
    margin-right: 5px;
}

.p_op4::before {
    content: url(/static/media/clock.f31a524b.svg);
    position: relative;
    top: 4px;
    margin-right: 5px;
}

.price_info {
    color: #656453;
    font-weight: 300;
}

.price_text {
    color: #ADB0C2;
    font-size: 15px;
}

.price_continer {
    text-align: end;
}

.deleteBtn {
    margin-top: 10px;
}

/* profile area end */


/* registry start */
.r_select_area {
    display: grid;
    grid-template-columns: 200px 1fr;
    align-items: center;
}

.rsa_text {
    border: 1px solid #C1C4D1;
    padding: 10px;
    margin: 0;
    border-radius: 20px;
    font-size: 15px;
    display: inline;
}

.r_select_area_inner {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.r_pcard {
    grid-template-columns: 160px 2fr 1fr 150px !important;
}

.r_pcard_child3 {
    background-color: #adb0c23a;
    padding: 10px;
    border-radius: 10px;
}

/* registry end */


/* service start */
/* --------------------------------------- */

/* servie checkbox start */
.s_container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 20px;
    -webkit-user-select: none;
    user-select: none;
}

.s_container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.s_container .checkmark {
    position: absolute;
    top: 3px;
    left: 0;
    height: 25px;
    width: 25px;
    border: 1px solid #C1C4D1;
    border-radius: 5px;
    background-color: #fff;
}

.s_container input:checked ~ .checkmark {
    background-color: #fff;
}

.s_container .checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.s_container input:checked ~ .checkmark:after {
    display: block;
}

.s_container .checkmark:after {
    left: 3px;
    top: 3px;
    width: 17px;
    height: 17px;
    background: var(--color-gold);
    border-radius: 5px;
}

.s_container {
    color: #656453;
}

/* servie checkbox end */


/* -------------------------------------------- */
/* service end */

@media only screen and (max-width: 1200px) {

    .nav_main {
        width: 95%;
    }

    .nav_main_child2 li a {
        padding: 10px;
    }

    .nav_main {
        grid-template-columns: 160px 1fr 269px;
    }
}

/* responsive start */


@media only screen and (max-width: 1024px) {
    .nav_main {
        grid-template-columns: 1fr 1fr 2fr;
        position: relative;
    }

    .menu {
        top: 50px;
        position: absolute;
        right: 0;
        background: #fff;
        padding: 28px 15px;
        width: 220px;
        border-radius: 14px;
        z-index: 2;
        border: 1px solid #d8d8d8b6;
    }

    .menu li {
        display: block;
        border-bottom: 1px solid #d8d8d8b6;
        text-align: center;
        padding: 10px;
    }

    .signuptbtnli {
        display: block !important;
        border-bottom: 0px solid transparent !important;
        padding: 10px !important;
        padding-bottom: 5px !important;
        padding-left: 0px !important;
    }

    .loginbtnli {
        display: block !important;
        border-bottom: 0px solid transparent !important;
        padding: 0px !important;
    }

    .desk_btn {
        /*display: none;*/
    }

    .shopping_cart_img {
        border: 0px solid transparent;
    }

    .humberger_menu {
        color: var(--color-grey);
        cursor: pointer;
    }

    .humberger_menu {
        display: inline;
        font-size: 22px;
        margin-left: 15px;
    }

    .h_section2_grid {
        overflow-x: auto;
    }

    .s2img {
        width: 800px;
    }

    .p_layout {
        grid-template-columns: 1fr;
    }
}

@media only screen and (min-width: 951px) {
    .desktop-none-950 {
        display: none;
    }
}

@media only screen and (max-width: 950px) {
    .r_select_area_inner {
        display: none;
    }

    .r_select_area_child1 {
        display: flex;
        justify-content: space-between;
    }

    .r_select_area {
        grid-template-columns: 1fr;
    }
}

@media only screen and (min-width: 769px) {
    .phn-price_continer {
        display: none;
    }

    .desktop-none {
        display: none;
    }

    .edit_gold {
        display: none;
    }

    .edit_white {
        color: #808080;
    }
}

@media only screen and (max-width: 768px) {
    .footer_grid {
        grid-template-columns: 1fr 1fr;
    }

    .fg_child1 {
        grid-column: 1/3;
        text-align: center;
        margin: 0 auto;
        margin-bottom: 50px;
    }

    .fg_child1 .logo_container {
        margin: 0 auto;
    }

    .fg_child4 {
        grid-column: 1/3;
        text-align: center;
        margin: 0 auto;
        margin-top: 50px;
    }

    .h_social_main {
        display: none;
    }

    .swiper-pagination-bullet {
        display: none !important;
    }

    .slider_grid {
        grid-template-columns: 1fr;
        grid-gap: 60px 0px;
        margin-top: 35px;
    }

    .service_main {
        grid-template-columns: 1fr 1fr;
    }

    .weh_main {
        grid-template-columns: 1fr 1fr;
    }

    .weh_child {
        height: 200px;
    }

    .p_card {
        grid-template-columns: 1fr;
    }

    .r_pcard {
        grid-template-columns: 1fr !important;
    }

    .p_card_child2 {
        display: flex !important;
        justify-content: center !important;
    }

    .p_card_child1 {
        display: flex;
        justify-content: space-between;
    }

    .p_c_img {
        width: 100px;
        height: 100px;
    }

    .desk-price_continer {
        display: none;
    }

    .pc_btn_container {
        display: flex;
        align-items: center;
    }

    .p_card_child4 {
        display: block;
    }

    .deleteBtn {
        margin-top: 0px;
        margin-left: 10px;
    }

    .mobile-none {
        display: none;
    }

    .filter_main {
        display: flex;
        align-items: center;
        border: 1px solid #65645359;
        padding: 10px 20px;
        border-radius: 20px;
        cursor: pointer;
    }

    .phn-none {
        display: none;
    }

    .p_card2 {
        padding: 10px 0px;
    }

    .edit_gold {
        position: absolute;
        right: 10px;
        top: 10px;
        cursor: pointer;
    }

    .edit_white {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
    }
}

@media only screen and (max-width: 600px) {
    .service_img {
        height: 250px;
    }

    .button2 {
        padding: 7px 10px;
        font-size: 15px;
    }

    .button1 {
        padding: 7px 10px;
        font-size: 15px;
    }

    .footer_grid {
        grid-template-columns: 1fr;
    }

    .logo_text {
        font-size: 22px;
    }

    .h_s1_carrosal_main {
        bottom: 0px;
        margin-top: 50px;
    }

    .h_s1_carrosal_main {
        padding: 20px 0px;
    }

    .slider_arrow {
        display: none;
    }

    .alice-carousel__dots {
        display: block !important;
    }

    .alice-carousel__dots-item:not(.__custom):hover, .alice-carousel__dots-item:not(.__custom).__active {
        background-color: var(--color-grey) !important;
    }

    .alice-carousel__dots-item:not(.__custom) {
        background-color: #656453;
        opacity: .5;
    }

    .chatIconDiv {
        right: 15px;
        bottom: 15px;
    }

    .footer_banner_main {
        padding: 50px 40px;
    }

    .footer_bnner_s_btn {
        margin-top: 10px;
    }

    .fbm_card_right {
        right: -9px;
        top: 30px;
        transform: rotate(356deg);
    }

    .fbm_card {
        width: 76px;
        align-items: center;
        height: 88px;
    }

    .titlep1 {
        font-size: 27px;
    }

    .titlep2 {
        font-size: 27px;
    }

    .rta_icon {
        width: 100%;
    }

    .fh_main {
        grid-template-columns: 1fr 1fr
    }

    .fh_main_child3 {
        grid-column: 1/3;
        margin: 0 auto;
    }

    .fh_main_child3 img {
        width: 70%;
    }
}

.input_file_area input {
    width: 100%;
    height: 100%;
    opacity: 0;
    position: relative;
    z-index: 2;
    cursor: pointer;
}

.input_file_area {
    width: 90px;
    height: 100px;
    border: 1.5px dashed #d9d9d9;
    border-radius: 46px;
    position: relative;
    /* background-image: repeating-linear-gradient(185deg, #d9d9d9, #d9d9d9 14px, transparent 14px, transparent 25px, #d9d9d9 25px), repeating-linear-gradient(275deg, #d9d9d9, #d9d9d9 14px, transparent 14px, transparent 25px, #d9d9d9 25px), repeating-linear-gradient(5deg, #d9d9d9, #d9d9d9 14px, transparent 14px, transparent 25px, #d9d9d9 25px), repeating-linear-gradient(95deg, #d9d9d9, #d9d9d9 14px, transparent 14px, transparent 25px, #d9d9d9 25px);
      background-size: 2px 100%, 100% 2px, 2px 100%, 100% 2px;
      background-position: 0 0, 0 0, 100% 0, 0 100%;
      background-repeat: no-repeat; */
    text-align: center;
    object-fit: cover;
}

.input_file_area_registry {
    height: 100px;
    position: relative;
}

.input_file_area_registry input {
    width: 100%;
    height: 100%;
    opacity: 0;
    position: relative;
    z-index: 2;
    cursor: pointer;
}

.input_image_main {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    z-index: 1;
}

.input_file_img {
    display: block;
    margin: 0 auto;
}

/* responsive end */

.accordation_acc_Head__1k-FB {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    padding: 10px;
}

.accordation_cardHeader__3SAGL {
    background-color: transparent !important;
    padding: 0 !important;
    border-radius: 20px !important;
}

.accordation_c_Card__3r9wJ {
    border-radius: 20px !important;
    margin-bottom: 20PX !important;
}

.accordation_arrowdiv__rJpzn {
    background-color: var(--color-gold);
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.accordation_IoIosArrowDown__3AahN{
    color: #fff;
}

.accordation_c_accordation__2nMYJ{
    width: 70%;
    margin: 0 auto;
}

/* responsive start */
@media only screen and (max-width: 768px) {
    .accordation_c_accordation__2nMYJ{
        width: 100%;
    }
}
@media only screen and (max-width: 600px) {

}
/* responsive end */
.fsp {
  position: fixed;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;
  width: 100%;
  height: 100vh;
  z-index: 220 !important;
  align-items: center;
  justify-content: center;
}

.fsp_overlay {
  position: fixed;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;
  margin: auto;
  width: 100% !important;
  height: 100vh !important;
  z-index: 222 !important;
  background: #00000050;
}

.fsp_content_wrapper {
  position: absolute;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;
  background: #ffffb3;
  border: 1px solid #65645359;
  border-radius: 20px;
  margin: auto;
  overflow-x: auto;
  z-index: 223 !important;
  width: 400px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  transition: all 0.3s;
}

.fsp_header {
  border-bottom: 1.2px solid #eaedf3;
  height: 70px;
  padding: 0px 24px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.fsp_content {
  padding: 24px;
  width: 100%;
}

@media (max-width: 640px) {
  .fsp_content_wrapper {
    width: 95% !important
  }
}

